import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import PlaceholderPage from "components/PlaceholderPage";
import { useNewWebsite } from "context/client/new_website";
import { useEffect } from "react";
import { useApp } from "context/app";

const mainClass = "new-website-content__confirmation";

const Confirmation = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const { id } = useParams();

  const query = new URLSearchParams(search);

  const { user, websites } = useApp();
  const { change_plan_mode } = useNewWebsite();

  const website_id = !!change_plan_mode ? id : query.get("id");
  const title = !!change_plan_mode
    ? t("Your plan was successfully changed.")
    : t("Your website was successfully added to our system.");

  useEffect(() => {
    let selected_website = websites?.find((item) => item?._id === website_id);
    const formatted_value = (selected_website?.subscription?.amount / 100)
      ?.toString()
      ?.replace(",", ".");

    window.gtag("event", "purchase", {
      transaction_id: selected_website?._id,
      value: formatted_value,
      email: user?.email,
      currency: selected_website?.subscription?.currency,
      items: [
        {
          item_id: selected_website?.subscription?.price_id,
          item_name: selected_website?.subscription?.name,
          price: formatted_value,
          quantity: 1,
        },
      ],
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={mainClass}>
      <div className={`${mainClass}__wrapper`}>
        <PlaceholderPage
          title={title}
          text={t(
            "Please note that for us to be able to carry out tasks you create and fully administer your website we will need the access data to your CMS system (WordPress), server, and database. You can fill out the access data in a secured form in the last step by clicking on 'Add access data' or we will contact you directly to receive the access in order to be able to carry out our services."
          )}
          button={[
            {
              label: t("Add access data"),
              href: `/websites/${website_id}/access-data`,
            },
            {
              label: t("Manage your website"),
              href: `/websites/${website_id}`,
              variant: "transparent",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Confirmation;
